// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_TEAM_MEMBERS,
  API_METHOD_GET,
  API_CUSTOMERS,
} from '@/constants/apiConstants';
import { SearchTeamMembersProps } from '@/helpers/types';

export const SearchCustomersData = async (
  searchTerm: string,
  status?: number,
  pageSize?: string,
  pagination?: number,
) => {
  const method: Method = API_METHOD_GET;
  const url =
    API_CUSTOMERS +
    `?search=${searchTerm}` +
    (status !== 100 ? `&f-is_active=${status}` : '') +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
