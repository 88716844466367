// api/register.ts
import axios, { Method } from 'axios';
import { generateHash } from '@/utility/auth/generateHashValue';
import { apiCall } from '@/utility/api/apiUtils';
import { CustomerSite } from '@/helpers/types';
import {
  API_CUSTOMER_SITES,
  API_CUSTOMER_SITES_UPDATE,
  API_METHOD_POST,
  API_METHOD_PUT,
} from '@/constants/apiConstants';

export const UpdateCustomerSiteData = async (
  customerUuid: string,
  siteUuid: string,
  values: CustomerSite,
) => {
  const method: Method = API_METHOD_PUT;
  const url = API_CUSTOMER_SITES_UPDATE(customerUuid, siteUuid);
  const data = {
    name: values?.name,
    details: values?.details,
    street_number: values.address.street_number,
    street_name: values.address.street_name,
    suburb: values.address.suburb,
    city: values.address.city,
    postal_code: values.address.postal_code,
    region: values.address.region,
    country: values.address.country,
    latitude: values.address.latitude,
    longitude: values.address.longitude,
    search_keyword: values.address.search_keyword,
  };

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
