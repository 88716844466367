import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_CUSTOMERS, API_METHOD_PUT } from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Customer } from '@/helpers/types';

export const UpdateCustomer = async (
  customerUuid: string,
  values: Customer,
) => {
  const method: Method = API_METHOD_PUT;
  const url = `${API_CUSTOMERS}/${customerUuid}`;
  const data = values;

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error;
  }
};
