// api/register.ts
import axios, { Method } from 'axios';
import { generateHash } from '@/utility/auth/generateHashValue';
import { apiCall } from '@/utility/api/apiUtils';
import { CustomersTypes } from '@/helpers/types';
import {
  API_CUSTOMER_NOTES,
  API_CUSTOMER_SITES,
  API_METHOD_POST,
} from '@/constants/apiConstants';

export const AddCustomerNoteData = async (
  customerUuid: string,
  newCustomerNote: { note: string; attachment_url: string | null }, // Allow `null` for attachment_url
) => {
  const method: Method = API_METHOD_POST;
  const url = API_CUSTOMER_NOTES(customerUuid);

  const data = {
    note: newCustomerNote.note,
    attachment: newCustomerNote.attachment_url, // Send `null` or file ID
  };

  const jsonString = JSON.stringify(data);

  const additionalHeaders = {
    hash: generateHash(jsonString), // Generate the hash for security
  };

  try {
    const response = await apiCall({
      method,
      url,
      data, // Send data to the API
      headers: additionalHeaders,
    });

    return response; // Return the response
  } catch (error) {
    throw error; // Rethrow the error for the thunk to handle
  }
};
