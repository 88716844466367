import { API_CUSTOMERS, API_METHOD_DELETE } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const DeleteCustomersNoteData = async (
  customerUuid: string,
  noteUuid: string,
) => {
  const method: Method = API_METHOD_DELETE;
  const url = API_CUSTOMERS + `/${customerUuid}/notes/${noteUuid}`;

  try {
    const response = await apiCall({
      method,
      url,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
