// api/register.ts
import axios, { Method } from 'axios';
import { generateHash } from '@/utility/auth/generateHashValue';
import { apiCall } from '@/utility/api/apiUtils';
import { CustomerNote, CustomerSite } from '@/helpers/types';
import {
  API_CUSTOMER_NOTES_UPDATE,
  API_CUSTOMER_SITES,
  API_CUSTOMER_SITES_UPDATE,
  API_METHOD_POST,
  API_METHOD_PUT,
} from '@/constants/apiConstants';

export const UpdateCustomerNoteData = async (
  customerUuid: string,
  noteUuid: string,
  formData: CustomerNote,
) => {
  const method: Method = API_METHOD_PUT;
  const url = API_CUSTOMER_NOTES_UPDATE(customerUuid, noteUuid);

  const data = {
    note: formData.note,
    attachment: formData.attachment_url,
  };

  const jsonString = JSON.stringify(data);

  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: {
        ...additionalHeaders,
      },
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
