import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  Customer,
  CustomerNote,
  CustomerSite,
  Pagination,
  SingleCustomerData,
  CustomerTitleData,
} from '@/helpers/types';
import { GetCustomersData } from '@/api/customers/getCustomers';
import { SearchCustomersData } from '@/api/customers/searchCustomers';
import { DeleteCustomerData } from '@/api/customers/deleteCustomer';
import { AddCustomerSiteData } from '@/api/customers/addCustomersSite';
import { DeleteCustomersNoteData } from '@/api/customers/deleteCustomersNote';
import { AddCustomerData } from '@/api/customers/addCustomer';
import { UpdateCustomerSiteData } from '@/api/customers/updateCustomersSite';
import { AddCustomerNoteData } from '@/api/customers/addCustomersNote';
import { UpdateCustomerNoteData } from '@/api/customers/updateCustomersNote';
import { GetCustomerById } from '@/api/customers/getCustomerById';
import { GetCustomerTitles } from '@/api/customers/getCustomerTitles';
import { toast } from 'react-toastify';
import { UpdateCustomer } from '@/api/customers/updateCustomer';
import { ImportCustomer } from '@/api/customers/importCustomer';

// Define the type for the API response
interface CustomersResponse {
  data: Customer[];
  pagination: Pagination;
}

interface CustomerByIdResponse {
  data: SingleCustomerData;
}

// Define the type for the thunk return value
type FetchCustomersResponse = {
  data: Customer[];
  pagination: Pagination;
};

type FetchCustomersError = string | SerializedError;
type SearchCustomersArgs = {
  searchTerm: string;
  selectedStatus: number;
  pageSize: string;
  pagination: number;
};
type DeleteCustomersArgs = string;
type SingleCustomersArgs = string;
type CustomerSiteArgs = {
  customerUuid: string;
  newCustomerSite: CustomerSite;
};
type DeleteCustomersNoteArgs = {
  customerUuid: string;
  noteUuid: string;
};
type updateCustomerSiteArgs = {
  customerUuid: string;
  siteUuid: string;
  customerSite: CustomerSite;
};
interface ImportCustomerPayload {
  file_id: string | null;
  import_type: string;
}
interface ImportCustomerResponse {
  data: any; // Replace `any` with the actual expected data structure
  message: string;
}

type CustomerNoteArgs = {
  customerUuid: string;
  newCustomerNote: {
    note: string;
    attachment_url: string | null; // Allow `null` for no attachment
  };
};

type updateCustomerNoteArgs = {
  customerUuid: string;
  noteUuid: string;
  customerNote: CustomerNote;
};

interface CustomerTitlesResponse {
  data: CustomerTitleData[];
}

type updateCustomerArgs = {
  customerUuid: string;
  customerData: Customer;
};

export const fetchCustomers = createAsyncThunk<
  FetchCustomersResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchCustomersError; // Type for rejected value
  }
>('customers/fetchCustomers', async (_, { rejectWithValue }) => {
  try {
    const response = await GetCustomersData();
    const data: CustomersResponse = response.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchCustomers = createAsyncThunk<
  FetchCustomersResponse,
  SearchCustomersArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchCustomersError;
  }
>(
  'customers/searchCustomers',
  async (
    { searchTerm, selectedStatus, pageSize, pagination },
    { rejectWithValue },
  ) => {
    // Destructure the argument
    try {
      const response = await SearchCustomersData(
        searchTerm,
        selectedStatus,
        pageSize,
        pagination,
      ); // Pass both parameters
      const data: CustomersResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteCustomer = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteCustomersArgs, // Argument type of the payload creator
  {
    rejectValue: FetchCustomersError; // Type for rejected value
  }
>(
  'customers/deleteCustomers',
  async (customerUuId: string, { dispatch, rejectWithValue }) => {
    try {
      // Call the API to delete the team member
      await DeleteCustomerData(customerUuId);
      toast.success('Customer deleted successfully!');
      // After successful deletion, dispatch fetchTeamMembers to refresh the list
      dispatch(fetchCustomers());
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const addCustomer = createAsyncThunk<
  Customer,
  Customer,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customers/addCustomers',
  async (newCustomer: Customer, { rejectWithValue, dispatch }) => {
    try {
      const response = await AddCustomerData(newCustomer); // Function to send a POST request
      toast.success(response.message);
      dispatch(fetchCustomers());
      return response.data; // Return the created customer
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const updateCustomer = createAsyncThunk<
  Customer,
  updateCustomerArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customers/updateCustomers',
  async ({ customerUuid, customerData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await UpdateCustomer(customerUuid, customerData); // Function to send a POST request
      toast.success(response.message);
      dispatch(fetchCustomers());
      return response; // Return the created customer site
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const fetchCustomerById = createAsyncThunk<
  CustomerByIdResponse, // The expected return type
  SingleCustomersArgs, // The expected argument type (UUID)
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>(
  'customers/fetchCustomerById',
  async (customerUuid: string, { rejectWithValue }) => {
    try {
      const response = await GetCustomerById(customerUuid);
      return response.data; // Return the single customer data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchCustomerTitles = createAsyncThunk<
  CustomerTitlesResponse, // The expected return type
  void,
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>('customers/fetchCustomerTitles', async (_, { rejectWithValue }) => {
  try {
    const response = await GetCustomerTitles();
    const data: CustomerTitlesResponse = response;
    return { data: data.data }; // Return formatted data
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});

export const importCustomer = createAsyncThunk<
  ImportCustomerResponse, // Success return type
  ImportCustomerPayload, // Payload type
  { rejectValue: { message: string; messages?: Record<string, string[]> } } // Error type
>(
  'customers/importCustomer', // Action type
  async (payload: ImportCustomerPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await ImportCustomer(payload); // API call

      toast.success(response.message); // Display success message

      dispatch(fetchCustomers()); // Optionally fetch customers after import

      return response; // Return the response data to handle it in the reducer
    } catch (error: any) {
      // Handle API error response
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      // Handle generic error
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      // Handle unknown error
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const addCustomerSite = createAsyncThunk<
  Customer,
  CustomerSiteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customers/addCustomerSite',
  async ({ customerUuid, newCustomerSite }, { rejectWithValue, dispatch }) => {
    try {
      const response = await AddCustomerSiteData(customerUuid, newCustomerSite); // Function to send a POST request
      dispatch(fetchCustomerById(customerUuid));
      return response; // Return the created customer site
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const updateCustomerSite = createAsyncThunk<
  Customer,
  updateCustomerSiteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customers/updateCustomerSite',
  async (
    { customerUuid, siteUuid, customerSite },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await UpdateCustomerSiteData(
        customerUuid,
        siteUuid,
        customerSite,
      ); // Function to send a POST request
      dispatch(fetchCustomerById(customerUuid));
      return response; // Return the created customer site
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const addCustomerNote = createAsyncThunk<
  Customer,
  CustomerNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customers/addCustomerNote',
  async ({ customerUuid, newCustomerNote }, { rejectWithValue, dispatch }) => {
    try {
      const response = await AddCustomerNoteData(customerUuid, newCustomerNote); // API call to save the note
      dispatch(fetchCustomerById(customerUuid)); // Refresh the customer list after success
      return response; // Return the response data
    } catch (error) {
      // Handle known error types
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // Fallback for unknown errors
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const updateCustomerNote = createAsyncThunk<
  Customer,
  updateCustomerNoteArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'customers/updateCustomerNote',
  async (
    { customerUuid, noteUuid, customerNote },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await UpdateCustomerNoteData(
        customerUuid,
        noteUuid,
        customerNote,
      ); // Function to send a POST request
      dispatch(fetchCustomerById(customerUuid));
      return response; // Return the created customer site
    } catch (error) {
      // Type guard to narrow down error to Error type
      if (error instanceof Error) {
        return rejectWithValue(error);
      }
      // Fallback for unknown error types
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const deleteCustomersNote = createAsyncThunk<
  void,
  DeleteCustomersNoteArgs, // Argument type of the payload creator
  {
    rejectValue: FetchCustomersError; // Type for rejected value
  }
>(
  'customers/deleteCustomersNote',
  async ({ customerUuid, noteUuid }, { rejectWithValue, dispatch }) => {
    // Added dispatch to parameters
    try {
      // Call the API to delete the customer note
      await DeleteCustomersNoteData(customerUuid, noteUuid); // Fixed variable name

      // After successful deletion, dispatch fetchCustomers to refresh the list
      dispatch(fetchCustomerById(customerUuid)); // Ensure fetchCustomers is correctly imported
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
